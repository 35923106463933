<template>
  <div>
    <div class="d-flex flex-column">
      <TrackingHistoricalPositions v-if="renderComponent"/>
    </div>
  </div>  
</template>

<script>
import TrackingHistoricalPositions from "@/components/tracking-historical-positions.vue";


export default {
  name: "historical",
    components: {
    TrackingHistoricalPositions
  },
  data() {
    return {
        renderComponent: true,
        polling: null,
    }
  },
  mounted (){},
  methods: {
    forceRerender () {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    refresh(){
      this.polling = setInterval(() => {
        this.forceRerender();
      }, 1000);  
    },
  },

  beforeDestroy () {},
  created () {}

}

</script>
