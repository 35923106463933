<template>
  <div class="homeContainer" :style="backgroundStylesByDomain()">
    <HomeComponent msg="ControlBoard App" />
  </div>
</template>
<script>
import HomeComponent from "@/components/HomeComponent";

//Getting domain name for styles variable
let host = location.hostname.toString();
let domains = JSON.parse(process.env.VUE_APP_DOMAINS);
let domainStyle = domains[host];

export default {
  name: "home",
  components: {
    HomeComponent,
  },

  mounted() {},
  methods: {
    backgroundStylesByDomain() {
      return {
        "background-image": `url(${require("@/assets/fondo-splash-" +
          domainStyle +
          ".jpg")})`,
      };
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.homeContainer {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}
</style>
