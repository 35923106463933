import Vue from "vue";
import Vuetify from "vuetify";
import colors from "vuetify/es5/util/colors";
import "vuetify/src/stylus/app.styl";

//Getting domain name for styles variable
let host = location.hostname.toString();
let domains = JSON.parse(process.env.VUE_APP_DOMAINS);
let domainStyle = domains[host];

let companyColor = "";
let headerColor = "";
let popupColor = "";

if (domainStyle === "camper") {
  companyColor = "#BD2D24";
  headerColor = "#BD2D24";
  popupColor = "#BD2D24";
} else if (domainStyle === "intesa") {
  companyColor = "#3DA8E3";
  headerColor = "#FFFFFF";
  popupColor = "#3DA8E3";
} else if (domainStyle === "atlantico") {
  companyColor = "#529085";
  headerColor = "#172C26";
  popupColor = "#529085";
} else if (domainStyle === "marpol") {
  companyColor = "#618ac5";
  headerColor = "#2d3238";
  popupColor = "#618ac5";
}

Vue.use(Vuetify, {
  theme: {
    primary: {
      base: companyColor,
    },
    secondary: {
      base: headerColor,
    },
    tertiary: {
      base: popupColor,
    },
    accent: {
      base: "#fba83e",
    },
    head: {
      base: headerColor,
    },
    fontbase: "#fff",
    fontalt: "#000",
    darkCard: "#1a253a",
    error: "#e3494b",
  },
  options: {
    customProperties: true,
  },
});

export default {};
