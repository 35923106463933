import axios from "axios";
import Cookies from "js-cookie";

const ENDPOINT_PATH = process.env.VUE_APP_API;

//Getting domain name for styles variable
let host = location.hostname.toString();
let domains = JSON.parse(process.env.VUE_APP_DOMAINS);
let currentDomain = domains[host];

export default {
  setUserLogged(userLogged) {
    Cookies.set("userLogged", userLogged, { expires: 365 });
  },
  getUserLogged() {
    return Cookies.get("userLogged");
  },
  login(email, password) {
    //const user = { email, password };
    return axios.post(
      ENDPOINT_PATH +
        "/api/login?" +
        "email=" +
        email +
        "&password=" +
        password +
        "&company=" +
        currentDomain
    );
  },
  logout() {
    Cookies.remove("userLogged");
  },
  refresh(id) {
    const body = { id };
    return axios.post(ENDPOINT_PATH + "/api/refresh", body);
  },
};
