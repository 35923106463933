<template>
  <v-touch @pan="panHandler" @rotate="rotateHandler">
    <div
      id="full_div"
      style="width: 100%; z-index:2000;"
      class="primary"
      fill-height
    >
      <v-container ma-0 pa-2 class="secondary" style="max-width: 100%;">
        <v-layout row class="header">
          <v-flex
            class="box"
            xs5
            sm5
            md5
            style="text-align: left; margin: auto;"
          >
            <img :src="truckIcon" class="truckIcon" />
            <img
              :src="headerLogo"
              @click="navigate('mapa')"
              class="headerLogo"
            />
          </v-flex>

          <v-flex class="box text-xs-right logout">
             <button 
                @click="navigate('mapa')"
                class="button-navigate">
                 <img :src="homeIcon" />
            </button>
            <img :src="logoutIcon" @click="logout()"/>
          </v-flex>
        </v-layout>
      </v-container>

      <l-map
        ref="myMap"
        :zoom="zoom"
        :center="center"
        :options="mapOptions"
        :zoomAnimation="true"
        :trackResize="true"
        :tap="true"
        :bounceAtZoomLimits="false"
        :viewreset="ViewRestart"
        style="height: 100vh; witdh: 100%;z-index:1; cursor: pointer"
        @update:center="centerUpdate"
        v-on:click="getcoords($event)"
        @ready="doSomethingOnReady()"
      >

        <l-control-layers
          :position="topright"
          :collapsed="false"
          :sort-layers="true"
        />
        <l-tile-layer
          v-for="tileProvider in tileProviders"
          :key="tileProvider.name"
          :name="tileProvider.name"
          :visible.sync="tileProvider.visible"
          :url="tileProvider.baseUrl"
          :attribution="tileProvider.attribution"
          format="image/png"
          layer-type="base"
          transparent
          class="titlelayer"
        />

        <l-wms-tile-layer
          v-for="layer in layers"
          :key="layer.name"
          :visible="layer.visible"
          :name="layer.name"
          :version="layer.version"
          :base-url="layer.baseUrl"
          :layers="layer.layers"
          transparent
          format="image/png"
          layer-type="overlay"
        />

        <l-control position="topright">
          <div class="select-vehicle-wrapper">
            <label 
              class="select-label-vehicle" 
              for="vehicle">
              Matrícula:
            </label>
            <select
              id="vehicle"
              class="select-vehicle"
              @change="onChangeCarDropdown($event.target.value)">
              <option 
                v-for="(vehicle, index) in vehicles" 
                :value="vehicle.imo" 
                :key="index">
                {{ vehicle.imo }}
              </option>
            </select>
        </div>
        </l-control>
      </l-map>

      <div
        class="arrow_box bocadillo "
        v-show="show"
        v-bind:style="styleObject"
      >
        <div class="TituloShip">
          <h2>{{ vehiclePopup.name }}</h2>
          <div class="botonClosePopup">
            <v-icon
              color="white"
              class="iconPopUPClose"
              @click="closePopupInfo()">
              far fa-times-circle
            </v-icon>
          </div>
        </div>
        <div
          class="fotoVessel"
          v-if="vehiclePopup.picture !== null && vehiclePopup.picture !== undefined">
          <img :src="vehiclePopup.picture" width="213" />
        </div>
        <div class="textoBocadillo">
            <p><strong>Matrícula: </strong>{{ vehiclePopup.imo }}</p>
            <p><strong>Velocidad: </strong>{{ vehiclePopup.sog }} Km/h</p>
            <p><strong>Fecha: </strong>{{ vehiclePopup.time }}</p>
        </div>
      </div>
    </div>
  </v-touch>
</template>
<script>
import axios from "axios";
import L from "leaflet";
import { CRS, latLng, icon, latLngBounds, map } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LControlLayers,
  LPopup,
  LTooltip,
  LWMSTileLayer,
  LGeoJson,
  LControl
} from "vue2-leaflet";
import "../compiled-icons/logoamura";
import WidgetsMenu from "./WidgtesMenu.vue";
import LogbookWidget from "./LogbookWidget.vue";
import authService from "@/services/authService";
import moment from 'moment';

//Getting domain name for styles variable
let host = location.hostname.toString();
let domains = JSON.parse(process.env.VUE_APP_DOMAINS);
let domainStyle = domains[host];
let company = domains[host];

export default {
  name: "historical-possitions",

  components: {
    LMap,
    "l-wms-tile-layer": LWMSTileLayer,
    LControlLayers,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LGeoJson,
    WidgetsMenu,
    LogbookWidget,
    LControl
  },
  props: ["currentToken"],
  data() {
    let lat;
    let lon;
    let pointsX;
    let pointsY;
    let points;
    let info;
    let icono;
    let imgflag;
    let pic;
    let horasF;
    let group1;
    let radar;
    let indice;

    let iconBarco = new L.icon({
      iconUrl: require("../assets/pin/pin-barco.png"),
      iconSize: [60, 60],
    });

    let iconGrua = new L.icon({
      iconUrl: require("../assets/pin/pin-grua.png"),
      iconSize: [60, 60],
    });
    let iconBarrera = new L.icon({
      iconUrl: require("../assets/pin/pin-barrera.png"),
      iconSize: [60, 60],
    });
    let iconBuzo = new L.icon({
      iconUrl: require("../assets/pin/pin-buzo.png"),
      iconSize: [60, 60],
    });

    let layersVar = [];

    return {
      vehicles: [],
      vehicleSelected: null,
      widgets: true,
      loading: false,
      visible: false,
      geojson: null,
      windowSizeX: 0,
      windowSizeY: "1300px",
      zoom: parseInt(process.env.VUE_APP_ZOOM),
      center: latLng(
        parseFloat(process.env.VUE_APP_LAT),
        parseFloat(process.env.VUE_APP_LON)
      ),
      url: process.env.VUE_APP_URLMAPBOXFONDO,
      bounds: latLngBounds([
        [
          parseFloat(process.env.VUE_APP_BOUNDLATSUP),
          parseFloat(process.env.VUE_APP_BOUNDLONSUP),
        ],
        [
          parseFloat(process.env.VUE_APP_BOUNDLATINF),
          parseFloat(process.env.VUE_APP_BOUNDLONINF),
        ],
      ]),
      maxBounds: latLngBounds([
        [
          parseFloat(process.env.VUE_APP_BOUNDLATSUP),
          parseFloat(process.env.VUE_APP_BOUNDLONSUP),
        ],
        [
          parseFloat(process.env.VUE_APP_BOUNDLATINF),
          parseFloat(process.env.VUE_APP_BOUNDLONINF),
        ],
      ]),
      showNoray: false,
      iconBarco,
      iconBarrera,
      iconBuzo,
      iconGrua,
      dialog: false,
      componentKey: 0,
      layers: layersVar,
      tileProviders: [
        {
          name: "Diurno",
          visible: true,
          attribution: process.env.VUE_APP_ATRIBUTTION,
          baseUrl: process.env.VUE_APP_URLMAPBOXDIURNO,
          zIndex: 1,
        },
        {
          name: "Nocturno",
          visible: false,
          baseUrl: process.env.VUE_APP_URLMAPBOXNOCTURNO,
          attribution: process.env.VUE_APP_ATRIBUTTION,
          zIndex: 1,
        },
      ],
      attribution: process.env.VUE_APP_ATRIBUTTION,
      currentZoom: parseInt(process.env.VUE_APP_ZOOM),
      currentCenter: latLng(
        parseFloat(process.env.VUE_APP_LAT),
        parseFloat(process.env.VUE_APP_LON)
      ),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 1,
        maxZoom: parseInt(process.env.VUE_APP_ZOOMMAX),
        minZoom: parseInt(process.env.VUE_APP_ZOOMMIN),
        draggable: false,
      },

      markers: [],
      vesselJson: [],
      stopMarkers: [],
      incidencias: [],
      points: [],
      info: [],
      geojson: null,
      markersNorays: [],
      flags: [],
      newNorays: [],
      capa: [],
      varBatimetria: false,
      incident: [],
      show: false,
      showPopupVessel: false,
      showE: false,
      showS: false,
      showP: false,
      showPU: false,
      showM: false,
      showN: false,
      layerCarga: null,
      layerActual: null,
      layerCargaNombre: L.layerGroup(),
      layerActualNombre: L.layerGroup(),
      layerCargaStatic: null,
      layerActualStatic: null,
      layerNorays: null,
      layerColorScale: null,
      layerActualNombreGruasStatic: null,
      layerSecurityAreasCargaStatic: null,
      layerSecurityAreasActualStatic: null,
      layerCargaNombreStatic: L.layerGroup(),
      layerActualNombreStatic: L.layerGroup(),
      layerCargaDynamic: null,
      layerActualDynamicCars: null,
      layerActualDynamicShips: null,

      layerCargaNombreDynamic: L.layerGroup(),
      layerActualNombreDynamicShips: L.layerGroup(),
      layerActualNombreDynamicCars: L.layerGroup(),
      trackingHistoricalPositionLayer: L.layerGroup(),
      redrawint: Math.floor(Math.random() * 200000) + 1,
      styleObject: {
        top: "130px",
        left: "130px",
      },
      vessellPopup: [],
      foto: "../assets/no-pic.jpg",
      truckIcon: require("@/assets/ic_camion-" + domainStyle + ".svg"),
      headerLogo: require("@/assets/logo-cabecera-" + domainStyle + ".png"),
      logoutIcon: require("@/assets/bt_salir-" + domainStyle + ".svg"),
      noTruckImage: require("@/assets/no-pic.jpg"),
      homeIcon: require("@/assets/bt-home-" + domainStyle + ".png"),
      vehiclePopup: {}
    };
  },

  async created() {
    if(process.env.VUE_APP_API_PORT_ESTATE !== '')
    {
      this.estadoInicial();
    }
  },

  mounted() {
    let Pusher = document.createElement("script");
    Pusher.setAttribute("src", "//js.pusher.com/7.0/pusher.min.js");
    document.head.appendChild(Pusher);
    let ckeditor2 = document.createElement("script");
    ckeditor2.setAttribute("src", "//cdn.jsdelivr.net/npm/vue/dist/vue.min.js");
    document.head.appendChild(ckeditor2);
  },

  methods: {
    logout() {
      authService.logout();
      this.$router.replace("/login");
    },
    navigate(path){
      this.$router.push("/"+ path);
    },
    doSomethingOnReady() {
      this.map = this.$refs.myMap.mapObject;
      this.getAllVehicles();
    },
    onChangeCarDropdown(vehicleId) {
      this.vehicleSelected = vehicleId;
      this.swapLayers();
    },
    getRedrawInteger() {
      return this.redrawint;
    },
    incrementRedrawInteger() {
      this.redrawint += 1;
    },
    wmsTrackingHistoricalPositions() {
      let randint = this.getRedrawInteger();
      this.trackingHistoricalPositionLayer = L.tileLayer.wms(
        process.env.VUE_APP_GEOSERVER_CARS + randint + `&CQL_FILTER=subtype='${company}' AND imo='${this.vehicleSelected}'`,
        {
          REQUEST: "GetMap",
          transparent: true,
          layers: process.env.VUE_APP_GEOSERVER_CARS_TYPE+':'+process.env.VUE_APP_GEOSERVER_CARS_LAYER,
          format: "image/png",
          version: "1.1.1",
          visible: true,
          zIndex: 5,
          tiled: false
        }
      );
    },
    getAllVehicles() {
      axios
        .get(process.env.VUE_APP_API.trim() + "/api/vehicles?company=" + company, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(authService.getUserLogged()).token,
          },
        })
        .then((response) => {
          if (response.data.success) {
            this.vehicles = response.data.success;
            if(this.vehicles.length > 0)
            {
              this.vehicleSelected = this.vehicles[0].imo;
              this.swapLayers();
            }
          }
          
        })
        .catch((error) => {
          console.error('error',error);
        });
    },

    getVehicleByPosition(latitude, longitude){
      const url = process.env.VUE_APP_API.trim() + "/api/vehicles/location?latitude=" + latitude + "&longitude=" + longitude + "&company=" + company;
      axios
        .get(url)
        .then((response) => {
          if(response != null && response.data.success)
          {
            this.vehiclePopup = {
                'imo': response.data.success.imo,
                'sog': response.data.success.sog ?? 0,
                'name': response.data.success.imo,
                'time': moment(String(response.data.success.time)).format('DD/MM/YYYY hh:mm')
            };
            this.showPopupInfo();
          }
        })
        .catch(() => {
          this.closePopupInfo();
        });
    },

    swapLayers() {
      this.incrementRedrawInteger();
      this.map.removeLayer(this.trackingHistoricalPositionLayer);
      this.wmsTrackingHistoricalPositions();
      this.map.addLayer(this.trackingHistoricalPositionLayer);

    },
    centerUpdate(center) {
      this.currentCenter = center;
    },

    getcoords(event) {
      if (this.show === true) {
        this.show = false;
      }
      this.lat = event.latlng.lat;
      this.lon = event.latlng.lng;
      this.getVehicleByPosition(this.lat, this.lon);
    },
    
    showPopupInfo() {
      this.show = true;
    },
    
    closePopupInfo() {
      this.show = false;
    },
  },
};
</script>
<style scoped>
.select-vehicle-wrapper {
  background-color: white;
  border: 2px solid rgba(0,0,0,.2);
  background-clip: padding-box;
  border-radius: 5px;
  padding: 5px 15px;
  
}
.select-label-vehicle {
  font-weight: 600;
  margin: 0;
}
.select-vehicle {
  padding-left: 5px;
  cursor: pointer;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}
.button-navigate {
    width: auto;
    height: auto;
    margin: 0 15px;
}
.button-navigate > img {
    width: 30px;
}
.iconPopClose {
  background-color: black;
  border-radius: 50px;
}
h2 {
  line-height: 1 !important;
  font-size: 12pt !important;
}
.arrow_box {
  position: relative;
  background: green;
  border: 1 px solid green;
}

.arrow_boxS {
  position: relative;
  background: #abb7b7;
  border: 1 px solid #abb7b7;
}

.arrow_boxE {
  position: relative;
  background: #b877db;
  border: 1 px solid #b877db;
}

.arrow_boxM {
  position: relative;
  background: #f5fa5e;
  border: 1 px solid #f5fa5e;
}

.arrow_boxP {
  position: relative;
  background: #f5fa5e;
  border: 1 px solid #f5fa5e;
}

.bocadillo {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: var(--v-tertiary-base);
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 5px;
  text-align: inherit;
  /* padding: 2px; */
  color: white;
  font-size: 16px;
  width: 217px;
}

.bocadilloS {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: #abb7b7;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
}

.bocadilloE {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: #293a5e;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
}

.bocadilloM {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: #abed00;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
}
.bocadilloP {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: #f5fa5e;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
  width: 217px;
}

.bocadilloPU {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: #ff4b66;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
  width: 217px;
}

.TituloShip {
  color: white;
  margin-left: 10px;
  line-height: 0.1 !important;
  padding-top: 8px;
  font-size: 9pt;
}

.TituloShipP {
  color: black;
  margin-left: 10px;
  line-height: 0.1 !important;
  padding-top: 8px;
  font-size: 9pt;
}

.bocadillo .textoBocadillo,
.bocadilloS .textoBocadillo,
.bocadilloE .textoBocadillo,
.bocadilloM .textoBocadillo,
.bocadilloP .textoBocadillo,
.bocadilloPU .textoBocadillo {
  background-color: #fff;
  color: black;
  line-height: 0.5;
  /*margin-top: 7px;*/
  font-size: 11pt;
  padding: 11px;
  padding-bottom: 1px;
  border-radius: 5px;
  padding-left: 20px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.dialogBox {
  width: 100%;
  height: 219px !important;
}
.botonClose {
  width: "100%";
  text-align: right;
}

.header {
  height: 50px;
}

.headerLogo {
  max-width: 250px;
}

.truckIcon {
  max-width: 30px;
  margin-left: 4%;
  margin-right: 5%;
}

.botonClosePopup {
  margin-top: -27px;
  margin-left: 176px;
  clear: both;
  position: absolute;
}

.txtCard {
  width: "100%";
  text-align: center !important;
}
.leaflet-popup-tip,
.leaflet-popup-content-wrapper {
  background: white !important;
  color: black !important;
}
.v-card {
  width: 100%;
  height: 200px;
}

.theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
button {
  width: 20%;
  height: 54px;
}
.pin {
  width: 75%;
}

.popupFlexCont {
  width: 400px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.popupFlexFoto {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.popupFooter {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.itemBandera {
  width: 13% !important;
  border: 1px solid aqua;
  padding: 3px;
  background-color: aqua;
  border-radius: 9px;
}
.fotoBandera {
  width: 100%;
}

.itemNombreBarco {
  color: blue;
  text-transform: uppercase;
  font-weight: bolder;
}

.typeship {
  color: black;
  font-weight: 100;
  text-transform: none;
}

.iconoRotacion {
  transform: rotateY(60deg);
}

.tile,
tile:hover {
  color: "primary";
}
.tile:active {
  color: "white";
}

.theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  background-color: #5e4529 !important;
  box-shadow: none !important;
}

.theme--light.v-btn {
  color: #293a5e !important;
}

.v-toolbar__content {
  padding: 0px !important;
}

.borde {
  border: 5px white solid;
  border-radius: 25px;
}

.fondosal {
  border-color: #abb7b7;
  background-color: #abb7b7;
}

.fondoent {
  border-color: #b877db;
  background-color: #b877db;
}

.fondobc {
  border-color: #abed00;
  background-color: #abed00;
}
.fondoPOB {
  border-color: #f5fa5e;
  background-color: #f5fa5e;
}

.fondobar {
  border-color: #ff4b66;
  background-color: #ff4b66;
}

.fondobat {
  border-color: #84cbff;
  background-color: #84cbff;
}

.fondoet {
  border-color: #76c888;
  background-color: #76c888;
}

.textcolor {
  color: white;
}

.v-btn {
  /* -webkit-box-align: center; */
  -ms-flex-align: center;
  /* align-items: center; */
  /* border-radius: 2px; */
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 36px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: 500;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 6px 0px;
  min-width: 88px;
  outline: 0;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  position: relative;
  /* vertical-align: middle; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.v-sheet {
  border-radius: 0px !important;
}
.fondoBarra {
  background-color: #2935a3;
}

.theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  background-color: #293a5e !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.detaBarra {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: 0px !important;
  margin-right: 0px !important;
  height: 58px;
}

.entradasBtm {
  width: 2vw;
  height: 2vw;
}

.incidentBtmBtm {
  width: 2vw;
  height: 2vw;
}

.buquesBtm {
  width: 2vw;
  height: 2vw;
}

.leaflet-layer {
  z-index: 1 !important;
}

/*//////////ESTILOS PORTRAIT ////////////////////////////////*/
@media only screen and (orientation: portrait) {
  .logoAmura {
    max-width: 150px;
    margin-left: 40px;
  }

  .entradasBtm {
    width: 8vw;
    height: 5vw;
  }

  .incidentBtmBtm {
    width: 4vw;
    height: 4vw;
  }

  .buquesBtm {
    width: 4vw;
    height: 4vw;
  }

  .bocadilloPU {
    /* min-width: 15vw;
    min-height: 6vh;*/
    background-color: #ff4b66;
    position: absolute;
    top: calc(500 - var(--pointsX));
    left: var(--pointsY);
    z-index: 12;
    border-radius: 10px;
    text-align: inherit;
    padding: 2px;
    color: white;
    font-size: 16px;
  }
}

.divider {
  border-color: #697ea8;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0 !important;
}

.logout {
  margin: auto;
  cursor: pointer;
  margin-right: 2%;
  display: flex;
  justify-content: flex-end;
  align-content: center;
}
</style>
<style lang="scss" scoped>
.v-card {
  background-color: #293a5e !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
