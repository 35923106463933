<template>
  <div class="loginContainer" :style="backgroundStylesByDomain()">
    <LoginComponent />
    <div class="footer"></div>
  </div>
</template>

<script>
import LoginComponent from "@/components/LoginComponent";

//Getting domain name for styles variable
let host = location.hostname.toString();
let domains = JSON.parse(process.env.VUE_APP_DOMAINS);
let domainStyle = domains[host];

export default {
  name: "login",
  components: {
    LoginComponent,
  },

  mounted() {},
  methods: {
    backgroundStylesByDomain() {
      return {
        "background-image": `url(${require("@/assets/fondo-login-" +
          domainStyle +
          ".jpg")})`,
      };
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.loginContainer {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position-y: 80%;
  display: flex;
  justify-content: center;
}
.footer {
  width: 90%;
  height: 10%;
  background-image: url("../assets/logos-innobonos-2020-negativo.png");
  background-size: contain;
  background-position: center;
  position: absolute;
  bottom: 10px;
}
</style>
